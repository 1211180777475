<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="row">
            <div class="col">
              <div class="container container-bloco-principal">
                <div class="row">
                  <div
                    class="col-md-8 col-lg-8 d-flex justify-content-center align-content-center"
                  >
                    <span class="container-bloco-principal-titulo"
                      >Ative uma campanha para o dia dos pais</span
                    >
                  </div>
                  <div
                    class="col-md-4 col-lg-4 w-100 d-flex justify-content-center border-left"
                  >
                    <span class="container-bloco-principal-subtitulo mt-sm-1"
                      >Alcança um total de 3.494 pessoas</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 no-gutters">
            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
              <div class="container-bloco mr-0">
                <span class="container-bloco-titulo">NPS</span><br />
                <div class="campo-estatistica">
                  <b-icon-bar-chart font-scale="1.6" style="color: #fff" />
                  <span class="container-bloco-subtitulo">75</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
              <div class="container-bloco mr-md-2 mr-lg-2 ml-2">
                <span class="container-bloco-titulo">Agendamentos</span><br />
                <div class="campo-estatistica">
                  <b-icon-bar-chart font-scale="1.6" style="color: #fff" />
                  <span class="container-bloco-subtitulo">84</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mt-sm-3 mt-md-0 mt-3">
              <div class="container-bloco">
                <span class="container-bloco-titulo">Confirmados</span><br />
                <div class="campo-estatistica">
                  <b-icon-bar-chart font-scale="1.6" style="color: #fff" />
                  <span class="container-bloco-subtitulo">63</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6 mt-sm-3 mt-md-0 mt-3">
              <div class="container-bloco ml-2">
                <span class="container-bloco-titulo">Aniversariantes</span
                ><br />
                <div class="campo-estatistica">
                  <b-icon-bar-chart font-scale="1.6" style="color: #fff" />
                  <span class="container-bloco-subtitulo">351</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 mt-md-3 mt-sm-3 mt-3 mt-lg-0">
          <div class="container container-bloco-terciario h-100">
            <div
              class="row no-gutters pt-5 pb-3 pr-1 pl-1 h-100 d-flex align-content-between"
            >
              <div
                class="col-md-4 mb-5 mb-md-2 col-lg-12 d-flex justify-content-center"
              >
                <span
                  class="container-bloco-terciario-titulo d-flex align-items-md-center"
                  >Campanha Outubro Rosa</span
                >
              </div>
              <div class="col-md-4 col-lg-6 pl-1">
                <div class="d-flex">
                  <b-icon-people font-scale="1.6" style="color: #fff" />
                  <p class="container-bloco-terciario-subtitulo ml-2">
                    Atualmente existem 4.122 mulheres na base de dados, onde
                    3.431 estão entre 18 à 50 anos.
                  </p>
                </div>
              </div>
              <div class="col-md-4 col-lg-6">
                <div class="d-flex">
                  <b-icon-bar-chart font-scale="1.6" style="color: #fff" />
                  <p class="container-bloco-terciario-subtitulo ml-2">
                    Esta campanha terá um impacto em 5.734 mulheres.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PainelInformativo"
};
</script>

<style>
.container-bloco-principal {
  display: flex;
  border: 1px solid #1bc5bd;
  border-radius: 10px;
  padding: 5px;
  background-color: #1bc5bd;
  transition: transform 0.2s;
}

.container-bloco-principal:hover {
  transform: scale(1.02);
}

.container-bloco-principal-titulo {
  /* font-family: 'Inter', Tahoma, Geneva, Verdana, sans-serif; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  font-size: calc(13px + 0.5vw);
  text-align: center;
  color: #fff;
}

.container-bloco-principal-subtitulo {
  font-family: "Inter", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100%;
  font-size: calc(7px + 0.5vw);
  text-align: center;
  color: #fff;
}

.container-bloco {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #3498db;
  border-radius: 10px;
  height: 120px;
  background-color: #3498db;
  transition: transform 0.2s;
  /* box-shadow: 0px 0px 1px #000; */
}

.container-bloco:hover {
  transform: scale(1.03);
}

.container-bloco-titulo {
  font-family: "Inter", Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  font-size: calc(10px + 0.3vw);
  font-weight: 500;
}

.container-bloco-subtitulo {
  font-family: "Inter", Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  font-size: calc(6px + 0.8vw);
}

.container-bloco-terciario {
  border: 1px solid #f43f5e;
  border-radius: 10px;
  margin-right: 10px;
  background-color: #f43f5e;
  transition: transform 0.2s;
}

.container-bloco-terciario:hover {
  transform: scale(1.02);
}

.container-bloco-terciario-titulo {
  color: #fff;
  font-weight: 500;
  font-size: calc(14px + 0.1vw);
}

.container-bloco-terciario-subtitulo {
  color: #fff;
  font-weight: 500;
  font-size: calc(10px + 0.1vw);
}
</style>
